import React from "react";
import { Typography, Chip, Box, Paper, Avatar, Stack } from "@mui/material";
import Upvote from "./Upvote";

const ClickableTile = ({
  tileInfo,
  handleTileClick,
  handleUpvote,
  handleRemoveUpvote,
}) => {
  const { id, topics, user_voted, title, user, score } = tileInfo;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 4,
        mb: 1,
        cursor: "pointer",
      }}
      onClick={handleTileClick}
    >
      {/* Left Part - Upvote Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ml: 1,
        }}
      >
        <Upvote
          id={id}
          userVoted={user_voted}
          handleUpvote={handleUpvote}
          handleRemoveUpvote={handleRemoveUpvote}
          score={score}
        />
      </Box>

      {/* Right Part - Content Section */}
      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1, p: 2 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Avatar
              sx={{
                bgcolor: (theme) => theme.palette.secondary.main,
                width: 24,
                height: 24,
              }}
            >
              {user[0]}
            </Avatar>
            <Typography variant="body2">{user}</Typography>
          </Stack>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {topics?.map((topic, idx) => (
            <Chip
              key={idx}
              label={topic.name}
              variant="outlined"
              size="small"
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ClickableTile;
