import React, { useState } from "react";
import { Chip, Typography, Paper } from "@mui/material";

const Filter = ({
  filterType,
  filterOptions,
  onProgramFilter,
  onYearFilter,
}) => {
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleProgramSelect = (program) => {
    const updatedPrograms = selectedPrograms.includes(program)
      ? selectedPrograms.filter((p) => p !== program)
      : [...selectedPrograms, program];
    setSelectedPrograms(updatedPrograms);
    onProgramFilter(updatedPrograms);
  };

  const handleYearFilter = (filter) => {
    const updatedFilters = selectedFilters.includes(filter)
      ? selectedFilters.filter((f) => f !== filter)
      : [...selectedFilters, filter];
    setSelectedFilters(updatedFilters);
    onYearFilter(updatedFilters);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: 4,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Filter
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {filterType}
      </Typography>
      {filterOptions.map((program, index) => (
        <Chip
          key={index}
          label={program}
          onClick={() => handleProgramSelect(program)}
          variant={selectedPrograms.includes(program) ? "filled" : "outlined"}
          color={selectedPrograms.includes(program) ? "primary" : "default"}
          sx={{ marginRight: 1, marginBottom: 1 }}
        />
      ))}
      {onYearFilter ? (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Year
          </Typography>
          {["1xx", "2xx", "3xx", "4xx"].map((filter) => (
            <Chip
              key={filter}
              label={filter}
              onClick={() => handleYearFilter(filter)}
              variant={selectedFilters.includes(filter) ? "filled" : "outlined"}
              color={selectedFilters.includes(filter) ? "primary" : "default"}
              sx={{ marginRight: 1 }}
            />
          ))}
        </>
      ) : null}
    </Paper>
  );
};

export default Filter;
