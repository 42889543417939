import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { auth } from "./firebase";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const loginWithUsernameAndPassword = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setNotice("Please provide both email and password.");
      return;
    }

    // Check if email is valid using regex
    if (!emailRegex.test(email)) {
      setNotice("Please provide a valid email address.");
      return;
    }

    if (password.length < 6) {
      setNotice("Password must be at least 6 characters long.");
      return;
    }

    try {
      setIsSubmitting(true);
      await signInWithEmailAndPassword(auth, email, password);
      navigate(-1);
    } catch (error) {
      setIsSubmitting(false);
      if (error.code === "auth/user-not-found") {
        setNotice("No user found with this email.");
      } else if (error.code === "auth/wrong-password") {
        setNotice("Incorrect password.");
      } else if (error.code === "auth/invalid-email") {
        setNotice("Please provide a valid email address.");
      } else if (error.code === "auth/invalid-credential") {
        setNotice("Invalid email or password.");
      } else {
        setNotice(
          "An error occurred. Please check that the email and password are valid or try again later."
        );
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Log In
        </Typography>
        {notice && (
          <Typography variant="body2" color="error" align="center" gutterBottom>
            {notice}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={loginWithUsernameAndPassword}
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!email || !password || isSubmitting}
          >
            {isSubmitting ? "Signing In..." : "Sign In"}
          </Button>
        </Box>
        <Typography variant="body2">
          Need to sign up for an account? <Link to="/sign-up">Click here</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
