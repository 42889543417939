import React, { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";

import WritableEditor from "./WritableEditor";

import * as Emoji from "quill-emoji";
import {
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Divider,
  CircularProgress,
  Chip,
  Tab,
  IconButton,
  Backdrop,
  Snackbar,
  Alert,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useParams } from "react-router-dom";
import {
  getQuestion,
  getSolutionPosts,
  createSolutionPost,
  voteSolutionPost,
  unvoteSolutionPost,
  voteQuestion,
  unvoteQuestion,
} from "./firebase";
import { Container } from "@mui/system";
import Taskbar from "./Taskbar";
import ClickableTile from "./ClickableTile";

import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import Comments from "./Comments";
import Upvote from "./Upvote";

Quill.register("modules/emoji", Emoji);

const QuestionSolutionsPage = () => {
  const { questionId } = useParams();
  const reactQuillRef = useRef(null);

  const [solutionSubmission, setSolutionSubmission] = useState("");
  const [solutionTitle, setSolutionTitle] = useState("");
  const [isSolutionSubmissionLoading, setIsSolutionSubmissionLoading] =
    useState(false);
  const [snackbarData, setSnackbarData] = useState({
    showSnackbar: false,
    snackbarMessage: "",
    isSnackbarError: false,
  });

  const [solutionTabs, setSolutionTabs] = useState([]);
  const [tabValue, setTabValue] = useState("1");

  const [questionResponse, setQuestionResponse] = useState({});
  const [solutionsResponse, setSolutionsResponse] = useState({});

  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [isSolutionLoading, setIsSolutionLoading] = useState(true);

  const { showSnackbar, snackbarMessage, isSnackbarError } = snackbarData;

  const questionQuery = { questionId };

  useEffect(() => {
    fetchQuestions();
    fetchSolutions();
  }, []);

  const fetchQuestions = async () => {
    setIsQuestionLoading(true);
    const questionData = await getQuestion(questionQuery);
    setQuestionResponse(questionData);
    setIsQuestionLoading(false);
  };

  const fetchSolutions = async () => {
    setIsSolutionLoading(true);
    const solutionsData = await getSolutionPosts(questionQuery);
    setSolutionsResponse(solutionsData);
    setIsSolutionLoading(false);
  };

  const handleQuestionUpvote = async (id) => {
    await voteQuestion({ questionId: id });
  };

  const handleRemoveQuestionUpvote = async (id) => {
    await unvoteQuestion({ questionId: id });
  };

  const LoadingSpinner = () => {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 4,
        }}
      >
        <CircularProgress />
      </Container>
    );
  };

  const QuestionsContent = () => {
    if (isQuestionLoading) {
      return <LoadingSpinner />;
    }

    if (Object.keys(questionResponse).length === 0) {
      return (
        <Typography>
          Unspecified client error setting question content
        </Typography>
      );
    }

    if (questionResponse.data.code !== 200) {
      const errorMessage = `Error fetching question content: ${questionResponse.code}`;
      return <Typography>{errorMessage}</Typography>;
    }

    const { id, title, body, score, topics, user_voted } =
      questionResponse.data.question;

    return (
      <Paper variant="outlined" sx={{ p: 3, borderRadius: 8 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography align="right">
            Posted by {questionResponse.data.question.user}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={0.5} sx={{ mb: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Upvote
              id={id}
              userVoted={user_voted}
              handleUpvote={handleQuestionUpvote}
              handleRemoveUpvote={handleRemoveQuestionUpvote}
              score={score}
            />
          </Box>
          {topics.map((topic, index) => (
            <Chip
              key={index}
              label={topic.name}
              variant="outlined"
              size="small"
              sx={{
                color: (theme) => theme.palette.secondary.contrastText,
                borderRadius: 4,
                marginRight: 2,
              }}
            />
          ))}
        </Box>
        <ReactQuill
          value={body}
          readOnly={true}
          theme="bubble"
          ref={reactQuillRef}
        />
        
      </Paper>
    );
  };

  const changeTabs = (solution) => {
    const currId = solution.id;
    const existingIndex = solutionTabs.findIndex(
      (solObj) => solObj.id === currId
    );

    if (existingIndex >= 0) {
      setTabValue((existingIndex + 2).toString());
      return;
    }

    const newIndex = solutionTabs.length + 2;
    setSolutionTabs([...solutionTabs, solution]);
    setTabValue(newIndex.toString());
  };

  const ErrorComponent = ({ errorMessage, DisplayIcon = <></> }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop="50px"
      >
        {DisplayIcon}
        <Typography variant="h5" color="#000000" align="center">
          {errorMessage}
        </Typography>
      </Box>
    );
  };

  const handleSolutionUpvote = async (id) => {
    const res = await voteSolutionPost({ solutionPostId: id });
    console.log(res);
    fetchSolutions();
  };

  const handleRemoveSolutionUpvote = async (id) => {
    await unvoteSolutionPost({ solutionPostId: id });
    fetchSolutions();
  };

  const SolutionsList = () => {
    if (isSolutionLoading) {
      return <LoadingSpinner />;
    }

    const ErrorIconDisplay = (
      <ErrorIcon
        style={{ fontSize: 100, color: "#000000", marginBottom: "20px" }}
      />
    );

    if (!solutionsResponse.data || !solutionsResponse.data.code) {
      return (
        <ErrorComponent
          DisplayIcon={ErrorIconDisplay}
          errorMessage="Error fetching solution data"
        />
      );
    } else if (solutionsResponse.data.code === 403) {
      const LockIconDisplay = (
        <LockOutlinedIcon
          style={{ fontSize: 100, color: "#000000", marginBottom: "20px" }}
        />
      );
      return (
        <ErrorComponent
          DisplayIcon={LockIconDisplay}
          errorMessage="You must attempt a solution before viewing other solutions"
        />
      );
    } else if (solutionsResponse.data.code === 401) {
      return (
        <ErrorComponent
          DisplayIcon={ErrorIconDisplay}
          errorMessage="You must sign in before viewing solutions"
        />
      );
    } else if (solutionsResponse.data.code !== 200) {
      return (
        <ErrorComponent
          DisplayIcon={ErrorIconDisplay}
          errorMessage={`Error ${solutionsResponse.code} - ${solutionsResponse.message}`}
        />
      );
    }

    return (
      <Box style={{ maxHeight: "calc(100% - 110px)", overflowY: "auto" }}>
        {solutionsResponse?.data?.solutionPosts?.map((solution, index) => (
          <ClickableTile
            key={index}
            tileInfo={solution}
            handleTileClick={() => changeTabs(solution)}
            handleUpvote={handleSolutionUpvote}
            handleRemoveUpvote={handleRemoveSolutionUpvote}
          />
        ))}
      </Box>
    );
  };

  const handleUserSubmission = async () => {
    if (solutionTitle === "" || solutionSubmission === "") {
      return;
    }

    setIsSolutionSubmissionLoading(true);

    const createQuery = {
      questionId,
      title: solutionTitle,
      body: solutionSubmission,
    };

    const createResponse = await createSolutionPost(createQuery);
    setIsSolutionSubmissionLoading(false);

    if (createResponse.data.code !== 200) {
      console.log("Error submitting answer");
      setSnackbarData({
        snackbarMessage: "Error submitting answer",
        isSnackbarError: true,
        showSnackbar: true,
      });
      return;
    }

    // Clear solution
    setSolutionSubmission("");
    setSolutionTitle("");
    setSnackbarData({
      snackbarMessage: "Successfully submitted answer",
      isSnackbarError: false,
      showSnackbar: true,
    });

    setIsSolutionLoading(true);
    const solutionQuery = {
      questionId,
    };
    const solutionsData = await getSolutionPosts(solutionQuery);
    console.log("Solution Posts" + JSON.stringify(solutionsData));
    setSolutionsResponse(solutionsData);
    setIsSolutionLoading(false);
  };

  const truncateTitle = (title) => {
    const stringLength = 16;
    const endingString = "...";

    if (title.length <= stringLength) {
      return title;
    }

    return `${title.substring(0, stringLength - 4)} ${endingString}`;
  };

  const deleteTab = (event, deletedId) => {
    
    event.stopPropagation();
    const newArr = solutionTabs.filter((solObj) => solObj.id !== deletedId);
    setSolutionTabs(newArr);
    console.log(tabValue);
    setTabValue("1");
    console.log(tabValue);
  };

  const closeSnackbar = () => {
    setSnackbarData({
      ...snackbarData,
      showSnackbar: false,
    });
  };

  const isEmptyContent = (htmlContent) => {
    const textContent = htmlContent.replace(/<[^>]*>/g, "").trim();
    return textContent === "";
  };

  return (
    <>
      <Taskbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSolutionSubmissionLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={showSnackbar}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={closeSnackbar}
          severity={isSnackbarError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box display="flex" height="100vh" padding={1}>
        {/* LEFT BOX */}
        <Box
          flex={1}
          border={1.5}
          borderRadius={4}
          marginRight={0.5}
          padding={2}
          sx={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)" }}
          flexDirection={"column"}
          height={"100%"}
          style={{ overflowY: "auto" }}
        >
          <Box>
            <QuestionsContent />
            <Divider sx={{ my: 2 }}>
              <Chip label="Solutions" size="large" color="primary" />
            </Divider>
          </Box>

          <Box flexGrow={1}>
            <SolutionsList />
          </Box>
        </Box>

        {/* RIGHT BOX */}
        <Box
          flex={1}
          border={1.5}
          borderRadius={4}
          marginLeft={0.5}
          padding={2}
          sx={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)" }}
          style={{ overflowY: "auto" }}
        >
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                variant="scrollable"
                aria-label="solution tabs"
                sx={{ mb: 2 }}
              >
                <Tab label="Submit" value="1"  onClick = {() => setTabValue((1).toString())}/>
                {solutionTabs.map(({ title, id }, ind) => (
                  <Tab
                    key={id}
                    onClick = {() => setTabValue((ind+2).toString())}
                    value={`${ind + 2}`}
                    label={
                      <span>
                        {truncateTitle(title)}
                        <IconButton
                          size="small"
                          component="span"
                          onClick={(event) => deleteTab(event, id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </span>
                    }
                  />
                ))}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box display="flex" alignItems="center" gap={2} sx={{ mb: 2 }}>
                  <TextField
                    label="Submission Title"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={solutionTitle}
                    onChange={(e) => setSolutionTitle(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                    onClick={handleUserSubmission}
                    disabled={
                      isEmptyContent(solutionTitle) ||
                      isEmptyContent(solutionSubmission)
                    }
                  >
                    Submit
                  </Button>
                </Box>
                <WritableEditor
                  content={solutionSubmission}
                  setContent={setSolutionSubmission}
                />
                {/* <ReactQuill
                  ref={reactQuillRef}
                  theme="snow"
                  placeholder="Submit your answer ..."
                  modules={{
                    toolbar: {
                      container: TOOLBAR_OPTIONS,
                    },
                    "emoji-toolbar": true,
                    "emoji-textarea": false,
                    "emoji-shortname": true,
                  }}
                  value={solutionSubmission}
                  onChange={setSolutionSubmission}
                /> */}
              </Box>
            </TabPanel>
            {solutionTabs.map(({ id, body }, ind) => (
              <TabPanel
                key={ind}
                value={`${ind + 2}`}
                sx={{ overflowY: "auto" }}
              >
                <ReactQuill
                  value={body}
                  readOnly={true}
                  theme="bubble"
                  ref={reactQuillRef}
                />
                <Comments solutionPostId={id} />
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default QuestionSolutionsPage;
