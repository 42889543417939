import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const functions = getFunctions();
const getCourses = httpsCallable(functions, "getCourses");
const getTopics = httpsCallable(functions, "getTopics");
const getQuestions = httpsCallable(functions, "getQuestions");
const createUser = httpsCallable(functions, "createUser");
const createQuestion = httpsCallable(functions, "createQuestion");
const getSolutionPosts = httpsCallable(functions, "getSolutionPosts");
const getQuestion = httpsCallable(functions, "getQuestion");
const createSolutionPost = httpsCallable(functions, "createSolutionPost");
const voteQuestion = httpsCallable(functions, "voteQuestion");
const unvoteQuestion = httpsCallable(functions, "unvoteQuestion");
const voteSolutionPost = httpsCallable(functions, "voteSolutionPost");
const unvoteSolutionPost = httpsCallable(functions, "unvoteSolutionPost");
const getSolutionComments = httpsCallable(functions, "getSolutionComments");
const createSolutionComment = httpsCallable(functions, "createSolutionComment");
const editSolutionComment = httpsCallable(functions, "editSolutionComment");
const deleteSolutionComment = httpsCallable(functions, "deleteSolutionComment");

export {
  app,
  analytics,
  auth,
  getCourses,
  getTopics,
  createUser,
  getQuestions,
  createQuestion,
  getSolutionPosts,
  getQuestion,
  createSolutionPost,
  voteQuestion,
  unvoteQuestion,
  voteSolutionPost,
  unvoteSolutionPost,
  getSolutionComments,
  createSolutionComment,
  editSolutionComment,
  deleteSolutionComment,
};
