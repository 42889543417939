import React, { useState } from "react";
import { IconButton, SvgIcon, Typography, Snackbar, Alert } from "@mui/material";
import { ReactComponent as UpVoteIcon } from "../assets/upvote.svg";
import { auth } from "./firebase";


const Upvote = ({ id, userVoted, score, handleUpvote, handleRemoveUpvote }) => {
  const [disabled, setDisabled] = useState(false);
  const [hasUserVoted, setHasUserVoted] = useState(userVoted);
  const [localScore, setLocalScore] = useState(score);
  const [snackbarData, setSnackbarData] = useState({
    showSnackbar: false,
    snackbarMessage: "",
    isSnackbarError: false,
  });
  const { showSnackbar, snackbarMessage, isSnackbarError } = snackbarData;

  const closeSnackbar = () => {
    setSnackbarData({
      ...snackbarData,
      showSnackbar: false,
    });
  };

  const handleClick = async (e) => {
    e.stopPropagation();    
    if(!auth.currentUser){
      setSnackbarData({
        showSnackbar: true,
        isSnackbarError: true,
        snackbarMessage: "You must sign in before upvoting",
      })
      return;
    }

    if (disabled) return;
    setDisabled(true);
    if (!hasUserVoted) {
      setHasUserVoted(true);
      setLocalScore(localScore+1);
      await handleUpvote(id);
    } else {
      setHasUserVoted(false);
      setLocalScore(localScore-1);
      await handleRemoveUpvote(id);
    }
    setDisabled(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={showSnackbar}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={closeSnackbar}
          severity={isSnackbarError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <IconButton onClick={handleClick} color="primary">
        <SvgIcon color={hasUserVoted ? "primary" : "action"}>
          <UpVoteIcon />
        </SvgIcon>
      </IconButton>
      <Typography variant="body2" color={hasUserVoted ? "primary" : "action"}>
        {localScore}
      </Typography>
    </>
  );
};

export default Upvote;
