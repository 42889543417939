import React, { useEffect, useState } from "react";
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'

import { 
  auth,
  getSolutionComments,
  createSolutionComment,
  editSolutionComment,
  deleteSolutionComment,
} from './firebase'

const Comments = (props) => {
  const [data, setData] = useState([]);

  function transformGetResponse(response) {
    if (response) {
      return response.map(parentComment => {
        const replies = parentComment.children.map(childComment => {
          return {
            userId: childComment.user,
            comId: childComment.id,
            fullName: childComment.user,
            userProfile: "",
            text: childComment.body,
            avatarUrl: "https://ui-avatars.com/api/name=" + childComment.user + "&background=random",
          };
        });
        return {
          userId: parentComment.user,
          comId: parentComment.id,
          fullName: parentComment.user,
          userProfile: "",
          text: parentComment.body,
          avatarUrl: "https://ui-avatars.com/api/name=" + parentComment.user + "&background=random",
          replies
        };
      });
    }
  }

  useEffect(() => {
    getSolutionComments({solutionPostId: props.solutionPostId})
      .then((result) => {
        const data = transformGetResponse(result.data.comments);
        setData(data);
      })
  }, []);

  async function onSubmitAction(data) {
    const createSolutionCommentData = {
      solutionPostId: props.solutionPostId,
      commentId: data.comId,
      body: data.text,
      parentCommentId: "null",
    };

    await createSolutionComment(createSolutionCommentData);
  }

  async function onReplyAction(data) {
    var parentCommentId = data.repliedToCommentId;

    if (data.parentOfReplliedCommentId) {
      parentCommentId = data.parentOfReplliedCommentId
    }

    const createSolutionCommentData = {
      solutionPostId: props.solutionPostId,
      commentId: data.comId,
      body: data.text,
      parentCommentId
    };

    await createSolutionComment(createSolutionCommentData);
  }

  async function onEditAction(data) {
    console.log(data);
    const editSolutionCommentData = {
      commentId: data.comId,
      body: data.text,
    };

    await editSolutionComment(editSolutionCommentData);
  }

  async function onDeleteAction(data) {
    console.log(data);
    const deleteSolutionCommentData = {commentId: data.comIdToDelete};

    await deleteSolutionComment(deleteSolutionCommentData);
  }

  return <CommentSection
      currentUser={{
        currentUserId: auth.currentUser.displayName,
        currentUserImg:
        "https://ui-avatars.com/api/name=me&background=random",
        currentUserProfile: "",
        currentUserFullName: auth.currentUser.displayName
      }}
      logIn={{
        loginLink: "/login",
        signupLink: "/sign-up"
      }}
      commentData={data}
      onSubmitAction={onSubmitAction}
      onReplyAction={onReplyAction}
      onEditAction={onEditAction}
      onDeleteAction={onDeleteAction}
    />
  }
  
  export default Comments