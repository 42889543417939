import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField, Typography, Box, Alert, Grid } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, createUser } from "./firebase";

const Signup = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const signupWithUsernameAndPassword = async (e) => {
    e.preventDefault();

    if (!isUsernameValid) {
      setError("Please ensure your username meets all requirements.");
      return;
    }

    if (!isPasswordValid) {
      setError("Please ensure your password meets all requirements.");
      return;
    }

    if (password === confirmPassword) {
      try {
        await createUser({ username, email, password });
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/");
      } catch (error) {
        setError(error.message);
      }
    } else {
      setError("Passwords don't match. Please try again.");
    }
  };

  const handleUsernameChange = (value) => {
    setUsername(value);
    setIsUsernameValid(checkUsername(value));
  };

  const checkUsername = (username) => {
    const isAlphaNumberic = /^[0-9a-z]+$/.test(username);
    const isCorrectLenght = username.length >= 8 && username.length <= 20;

    return isAlphaNumberic && isCorrectLenght;
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setIsPasswordValid(checkPasswordStrength(value));
  };

  const checkPasswordStrength = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    return password.length >= 6 && hasUppercase && hasLowercase && hasNumber;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Box mt={5} p={3} bgcolor="background.paper" boxShadow={3}>
          <Typography variant="h5" align="center" gutterBottom>
            Sign Up
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={signupWithUsernameAndPassword}>
            <TextField
              id="signupUsername"
              label="Username"
              type="username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => handleUsernameChange(e.target.value)}
              required
              error={!isUsernameValid && username.length !== 0}
              helperText={
                !isUsernameValid &&
                "Username must be at least 8 characters long and contain only letters and numbers."
              }
            />
            <TextField
              id="signupEmail"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              id="signupPassword"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              required
              error={!isPasswordValid && password.length !== 0}
              helperText={
                !isPasswordValid &&
                "Password must be at least 6 characters long and contain uppercase letters, lowercase letters, and numbers."
              }
            />
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              error={password !== confirmPassword}
              helperText={
                password !== confirmPassword ? "Passwords don't match" : ""
              }
            />
            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isPasswordValid}
              >
                Sign Up
              </Button>
            </Box>
            <Box mt={2} textAlign="center">
              <Typography>
                Already have an account? <Link to="/login">Log In</Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;
