import "./WritableEditor.css"
import katex from "katex";
import "katex/dist/katex.css";

import React, { useEffect, useRef } from "react";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

//MathQuill Dependency
import "./jquery";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

//Including Mathquill
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";

import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

window.katex = katex;
Quill.register("modules/emoji", Emoji);

const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "formula"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["emoji"],
    ["clean"],
];

const CUSTOM_OPERATORS = [
    ["\\pm", "\\pm"],
    ["\\sqrt{x}", "\\sqrt"],
    ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
    ["\\sqrt[n]{x}", "\\nthroot"],
    ["\\frac{x}{y}", "\\frac"],
    ["\\sum^{s}_{x}{d}", "\\sum"],
    ["\\prod^{s}_{x}{d}", "\\prod"],
    ["\\coprod^{s}_{x}{d}", "\\coprod"],
    ["\\int^{s}_{x}{d}", "\\int"],
    ["\\binom{n}{k}", "\\binom"]
];

const WritableEditor = ({ setContent, content }) => {
    const reactQuillRef = useRef(null);

    useEffect(() => {
        const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
        enableMathQuillFormulaAuthoring(
            reactQuillRef.current.editor,
            {
                displayHistory: true,
                operators: CUSTOM_OPERATORS,
                displayDeleteButtonOnHistory: true,
            }
        );
    }, [])

    return (<ReactQuill
        ref={reactQuillRef}
        theme="snow"
        placeholder="Submit your answer ..."
        modules={{
            formula: true,
            toolbar: TOOLBAR_OPTIONS,
            "emoji-toolbar": true,
            "emoji-textarea": false,
            "emoji-shortname": true,
        }}
        value={content}
        onChange={setContent}
    />
    )

}

export default WritableEditor;