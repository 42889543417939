import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import Taskbar from "./Taskbar";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Taskbar />
      <Container
        maxWidth="lg"
        sx={{
          pt: 12,
          pb: 24,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "600px" }}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            fontWeight="bold"
          >
            Access Relevant Problems, Collaborate, Excel in Courses
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Chasm is a learning platform that gives students access to relevant
            problems and the ability to collaborate with peers. Excel in your
            courses with Chasm.
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              component={Link}
              to="/courses"
              sx={{
                backgroundImage: (theme) =>
                  `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.light} 90%)`,
                color: "white",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Solution Mindset"
            src="/undraw_education_f8ru.svg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
