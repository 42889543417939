import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon

const SearchBox = ({ onSearch }) => {
  const handleInputChange = (event) => {
    const value = event.target.value;
    onSearch(value); // Call onSearch with the search term
  };

  return (
    <TextField
      size="small"
      fullWidth
      placeholder="Search courses..."
      onChange={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "white",
      }}
    />
  );
};

export default SearchBox;
