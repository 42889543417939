import React, { useEffect, useState } from "react";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

import WritableEditor from "./WritableEditor";

import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  Alert,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Link,
  CircularProgress, // Import CircularProgress for loading wheel
} from "@mui/material";
import Taskbar from "./Taskbar";
import { createQuestion, getTopics } from "./firebase"; // Import getTopics function
import { auth } from "./firebase";
import { useParams, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const QuestionForm = () => {
  const { courseSubject, courseCode } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [topicsList, setTopicsList] = useState([]); // Changed to topicsList
  const [preview, setPreview] = useState({ title: "", body: "" });
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [user, setUser] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]); // Added selectedTags state
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getTopics({ subject: courseSubject, code: courseCode });
        setTopicsList(result.data.topics);
      } catch (error) {
        console.log("FAILED TO FETCH TOPICS");
      }
    };

    fetchData();
  }, [courseSubject, courseCode]);

  const handlePreview = () => {
    setPreview({ title, body });
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true); // Start submitting
    const questionData = {
      title: title,
      body: body,
      topicIds: selectedTags, // Updated to include selected tags
      subject: courseSubject,
      code: courseCode,
      user: user?.username,
      score: 0,
      timestamp: new Date().toISOString(),
    };

    try {
      let z = await createQuestion(questionData);
      console.log(z);
      console.log(questionData);
      navigate("/courses/" + courseSubject + "/" + courseCode + "/questions/" + z.data.id);
    } catch (error) {
      console.error("Error creating question: ", error);
    } finally {
      setIsSubmitting(false); // Finish submitting
    }
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((item) => item !== tag)); // Remove tag if already in list
    } else {
      setSelectedTags([...selectedTags, tag]); // Add tag if not in list
    }
  };

  const isFormValid = () => {
    return title.trim() !== "" && body.trim() !== "";
  };

  return (
    <Box>
      <Taskbar />
        <Typography variant="h4" sx={{ textAlign: "center", mt: 4, mb: 4 }}>
          New Question for {courseSubject} {courseCode}
        </Typography>
      {user ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            mb: 2,
            pt: 2,
          }}
        >
          <Box sx={{ height: "50%", width: "50%", mr: 2 }}>
            <TextField
              fullWidth
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <WritableEditor
              content={body}
              setContent={setBody}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="outlined"
                onClick={handlePreview}
                disabled={!isFormValid()}
              >
                Preview
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ ml: 1 }}
                disabled={!isFormValid() || isSubmitting} // Disable button when submitting
              >
                {isSubmitting ? ( // Show loading wheel if submitting
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              flexDirection: "col",
              p: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "10px",
                p: 2,
                mb: 2,
              }}
            >
              <Typography variant="body1" sx={{ mb: 1 }}>
                Topics
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                {topicsList.map((tag) => (
                  <Button
                    key={tag.id}
                    variant={selectedTags.includes(tag.id) ? "contained" : "outlined"}
                    onClick={() => handleTagClick(tag.id)}
                    sx={{
                      mb: 1,
                      mr: 1,
                      borderRadius: "50px",
                      minWidth: "auto",
                      px: 1,
                      width: "fit-content",
                    }}
                  >
                    {tag.name}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>
          <Dialog
            open={isPreviewOpen}
            onClose={handleClosePreview}
            maxWidth="xl"
            fullWidth
          >
            <DialogTitle>
              <Chip label="Preview" variant="outlined" sx={{ mb: 1 }} />
              <Typography variant="h4">{preview.title}</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: preview.body }}
              ></Typography>
              <Box sx={{ mt: 2 }}>
                {selectedTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePreview}>Close</Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Alert severity="error" sx={{ borderRadius: 4 }}>
            You must log in to create a question. Please{" "}
            <Link component={RouterLink} to="/login">
              log in
            </Link>{" "}
            to continue.
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default QuestionForm;
