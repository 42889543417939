import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import CoursesPage from "./components/CoursesPage";
import ErrorPage from "./components/ErrorPage";
import Course from "./components/Course";
import QuestionSolutionPage from "./components/QuestionSolutionPage";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; // Import useHistory
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import Signup from "./components/Signup";
import Login from "./components/Login";
import LandingPage from "./components/LandingPage";
import QuestionsForm from "./components/QuestionsForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "courses",
    element: <CoursesPage />,
  },
  {
    path: "courses/:courseSubject/:courseCode",
    element: <Course />,
  },
  {
    path: "courses/:courseSubject/:courseCode/questionsForm/",
    element: <QuestionsForm />,
    // Use RequireAuth as a wrapper for QuestionsForm
  },
  {
    path: "courses/:courseSubject/:courseCode/questions/:questionId",
    element: <QuestionSolutionPage />,
    // Use RequireAuth as a wrapper for QuestionSolutionPage
  },
  {
    path: "sign-up",
    element: <Signup />,
  },
  {
    path: "login",
    element: <Login />,
  },
]);

const theme = createTheme({
  palette: {
    // mode: "dark",
    text: {
      primary: "#041016", // Text color
    },
    background: {
      default: "#f8fcfe", // Background color
    },
    primary: {
      main: "#3a9ede", // Primary color
    },
    secondary: {
      main: "#a987eb", // Secondary color
    },
    accent: {
      main: "#c656e2", // Accent color
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif", // Default font
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
