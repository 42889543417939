import React, { useEffect, useState } from "react";
import {
  Alert,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/system";
import { getCourses } from "./firebase";

const CoursesList = ({ searchInput, courseCodeFilter, programFilter, yearFilter, setFilterTopics }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    getCourses()
      .then((result) => {
        setCourses(result.data.courses);
        setLoading(false);

        let courseCodes = new Set();
        result.data.courses.forEach(course => {
          if(!course.subject){
           return;
          }
          courseCodes.add(course.subject);
        });

        setFilterTopics(Array.from(courseCodes));

      })
      .catch(() => {
        setError(
          "An error occurred while fetching courses. Please try again later."
        );
        setLoading(false);
      });
  }, []);

  const filterCoursesByCode = (pattern) => {
    if (!pattern) return courses;
    return courses.filter((course) =>
      pattern.some((filter) =>
        filter ? String(filter)[0] === String(course.code)[0] : true
      )
    );
  };

  const filteredCourses = filterCoursesByCode(courseCodeFilter).filter(
    (course) => {
      if (!course) return false;
      const subject = course.subject ? course.subject.toLowerCase() : "";
      const code = course.code ? course.code : "";
      const courseInfo = subject + " " + code;
      const name = course.name ? course.name.toLowerCase() : "";
      const search = searchInput ? searchInput.toLowerCase().trim() : "";
      const matchesSearchInput =
        courseInfo.includes(search) || name.includes(search);

      if (!matchesSearchInput) {
        return false;
      }


      const matchesProgramFilter = programFilter
        ? programFilter.length === 0 || programFilter.includes(course.subject)
        : true;

      if (!matchesProgramFilter) {
        return false;
      }

      const matchesYearFilter = (yearFilter.length === 0) || (course.code && yearFilter.some((year) => year.charAt(0) === course.code.toString().charAt(0)));

      if (!matchesYearFilter) {
        return false;
      }

      return true;
    }
  );

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  }));

  return (
    <Container maxWidth="md">
      <Paper elevation={0}>
        {loading ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 4,
            }}
          >
            <CircularProgress />
          </Container>
        ) : error ? (
          <Alert severity="error" sx={{ my: 2 }}>
            {error}
          </Alert>
        ) : (
          <Table>
            <TableHead>
              <StyledTableRow>
                <TableCell>
                  <Typography
                    sx={{
                      color: "primary.main",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Course Code
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: "primary.main",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Course Name
                  </Typography>
                </TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {filteredCourses.map((course, index) => (
                <StyledTableRow
                  onClick={() => {
                    navigate(`/courses/${course.subject}/${course.code}`);
                  }}
                  key={index}
                  sx={{
                    transition: "transform 0.5s ease, box-shadow 0.5s ease",
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.02)",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <TableCell sx={{ textDecoration: "underline" }}>
                    {course.subject + " " + course.code}
                  </TableCell>
                  <TableCell>{course.name}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </Container>
  );
};

export default CoursesList;
