import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as HomeIcon } from "../assets/chasm.svg";
import { Link as RouterLink } from "react-router-dom";
import { auth } from "./firebase";

const Taskbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <AppBar
      position="sticky"
      variant="outlined"
      sx={{ bgcolor: "background.default" }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="home"
          component={RouterLink}
          to="/"
          sx={{ mr: 2 }}
        >
          <HomeIcon />
        </IconButton>
        <Typography component="div" sx={{ mr: 2 }}>
          CHASM
        </Typography>
        <Button
          variant="text"
          component={RouterLink}
          to="/courses"
          sx={{ textTransform: "none", mr: "auto" }}
        >
          Explore Courses
        </Button>
        {isLoggedIn ? (
          <>
            <Typography component="div" sx={{ mr: 2 }}>
              Welcome, {auth.currentUser.displayName}! 
            </Typography>
            <Button
              component={RouterLink}
              to="/"
              onClick={handleLogout}
              sx={{ textTransform: "none" }}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              component={RouterLink}
              to="/login"
              sx={{ textTransform: "none", mr: 1 }}
            >
              Log In
            </Button>
            <Button
              component={RouterLink}
              to="/sign-up"
              sx={{ textTransform: "none" }}
            >
              Sign Up
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Taskbar;
