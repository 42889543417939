import React, { useState, useEffect } from "react";
import { Typography, Container, Box, Button, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Taskbar from "./Taskbar";
import SearchBox from "./SearchBox";
import ClickableTile from "./ClickableTile";
import Filter from "./Filter";
import {
  getQuestions,
  unvoteQuestion,
  voteQuestion,
  getTopics,
} from "./firebase";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const Course = () => {
  const { courseSubject, courseCode } = useParams();
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [questions, setQuestions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // State to track loading state
  const navigate = useNavigate();

  const data = { subject: courseSubject, code: courseCode };

  useEffect(() => {
    fetchQuestions();
    getTopics(data)
      .then((result) => {
        setTopics(result.data.topics.map((topic) => topic.name));
      })
      .catch(() => {
        setError(
          "An error occurred while fetching topics. Please try again later."
        );
      });
  }, []);

  const fetchQuestions = () => {
    setIsLoading(true); // Start loading
    getQuestions(data)
      .then((result) => {
        setQuestions(result.data.questions);
      })
      .catch(() => {
        setError(
          "An error occurred while fetching questions. Please try again later."
        );
      })
      .finally(() => {
        setIsLoading(false); // Finish loading
      });
  };

  const handleProgramFilter = (programs) => {
    setSelectedTopics(programs);
  };

  const handleQuestionClick = (question) => {
    navigate(`/courses/${courseSubject}/${courseCode}/questions/${question}`);
  };

  const handleUpvote = async (id) => {
    try {
      await voteQuestion({ questionId: id });
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === id
            ? { ...question, user_voted: true, score: question.score + 1 }
            : question
        )
      );
    } catch (error) {
      console.error("Error upvoting question: ", error);
    }
  };
  
  const handleRemoveUpvote = async (id) => {
    try {
      await unvoteQuestion({ questionId: id });
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === id
            ? { ...question, user_voted: false, score: question.score - 1 }
            : question
        )
      );
    } catch (error) {
      console.error("Error removing upvote from question: ", error);
    }
  };

  console.log(questions);
  return (
    <>
      <Taskbar />
      <Container maxWidth="md" sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            mb: 2,
          }}
        >
          <Typography variant="h4" fontWeight={700}>
            Course Name: {courseSubject + " " + courseCode}
          </Typography>
          <Typography variant="h5" fontWeight={700}>
            Questions
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SearchBox onSearch={(value) => setSearchInput(value)} />
          <Button
            component={RouterLink}
            to="questionsForm"
            variant="contained"
            color="primary"
            sx={{ ml: 1 }}
          >
            Create
          </Button>
        </Box>
        {isLoading ? ( // Show loading spinner while loading
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : questions.length === 0 ? ( // Show frowny face box if no questions
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              my: 4,
            }}
          >
            <Typography variant="h5" gutterBottom>
              Looks like there are no questions yet.
            </Typography>
            <Typography variant="body1">
              Post one to contribute!
            </Typography>
            <img
              src="https://emojicdn.elk.sh/%F0%9F%98%95"
              alt="frowny face"
              style={{ width: 100, height: 100, marginTop: 20 }}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Box flex={2} mr={2} mt={2}>
              {questions
                ?.filter((question) =>
                  question.title.toLowerCase().includes(searchInput.toLowerCase())
                )
                .filter(
                  (question) =>
                    selectedTopics.length === 0 ||
                    question.topics.some((topic) =>
                      selectedTopics.includes(topic.name)
                    )
                )
                .map((question, index) => (
                  <ClickableTile
                    key={index}
                    tileInfo={question}
                    handleTileClick={() => handleQuestionClick(question.id)}
                    handleUpvote={handleUpvote}
                    handleRemoveUpvote={handleRemoveUpvote}
                  />
                ))}
            </Box>
            <Box flex={1} ml={2} mt={2}>
              <Filter
                filterType="Topics"
                filterOptions={topics}
                onProgramFilter={handleProgramFilter}
              />
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default Course;
