import React, { useState } from "react";
import { Container, Box } from "@mui/material";
import SearchBox from "./SearchBox";
import CoursesList from "./CoursesList";
import Taskbar from "./Taskbar";
import Filter from "./Filter";

function CoursesPage() {
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [yearFilter, setYearFilter] = useState([]); // State for course code filter
  const [selectedPrograms, setSelectedPrograms] = useState([]); // State for selected programs
  const [filterTopics, setFilterTopics] = useState([]);

  const handleProgramFilter = (programs) => {
    setSelectedPrograms(programs);
  };

  const handleYearFilter = (filters) => {
    setYearFilter(filters.length > 0 ? filters : "");
  };

  return (
    <>
      <Taskbar />
      <Container maxWidth="md" sx={{ p: 2 }}>
        <SearchBox onSearch={(value) => setSearchInput(value)} />
        <Box display="flex" justifyContent="space-between">
          <Box flex={2} mr={2}>
            <CoursesList
              searchInput={searchInput}
              yearFilter={yearFilter}
              programFilter={selectedPrograms}
              setFilterTopics={setFilterTopics}
            />
          </Box>
          <Box flex={1} ml={2} mt={2}>
            <Filter
              filterType="Subjects"
              filterOptions={filterTopics}
              onYearFilter={handleYearFilter}
              onProgramFilter={handleProgramFilter}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default CoursesPage;
